import Image, { ImageProps } from 'next/image'
import { useState } from 'react'

type Props = ImageProps & { fallbackSrc: string }

export function NextFallbackImage({ src, fallbackSrc, width, height, alt }: Props) {
  const [imageSrc, setImageSrc] = useState(src)

  return (
    <Image
      height={height}
      width={width}
      src={imageSrc}
      style={{ objectFit: 'cover' }}
      onError={() => setImageSrc(fallbackSrc)}
      alt={alt}
    />
  )
}
