import { Heading, Stack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import { StartPageEquipmentCardList } from 'components/modules/StartPage/StartPageEquipmentCardList'

const IMAGE_URL = '/images/start-page-image.jpg'

const styles = {
  wrapper: {
    p: {
      base: 4,
      md: 10,
    },
    mt: -1,
    minH: { base: '264px', md: '694px' },
    bgColor: 'primary.gry.100',
    bgImage: IMAGE_URL,
    bgPosition: 'top',
    bgSize: 'cover',
    justifyContent: 'flex-end',
  },
  contentWrapper: {
    w: 'full',
    maxW: 'desktop',
    pl: 10,
    alignSelf: 'center',
  },
  heading: {
    fontSize: { base: '2rem', md: '56px' },
    color: 'primary.white',
    mb: { base: 2, md: 12 },
  },
} as const

export function StartPageHeader() {
  const { t } = useTranslation('start')

  return (
    <Stack {...styles.wrapper}>
      <Stack {...styles.contentWrapper}>
        <Heading as="h1" {...styles.heading}>
          {t('common:sites.store')}
        </Heading>
        <StartPageEquipmentCardList display={{ base: 'none', md: 'flex' }} />
      </Stack>
    </Stack>
  )
}
